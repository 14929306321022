

html {
    margin: 0;
    height: 100%;
}


body {
  margin: 0 0 0; /* bottom = footer height */
  padding: 0;
  font-family: sans-serif;
  background-color: darkgray;
  height: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: "main" "footer";
  grid-template-rows: 1fr 80px;
}
main {
    margin-top: 0px;
    grid-area: main;
}
footer {
    width: 100%;
    grid-area: footer;
}

button {
 margin: 3px;
 font-size: 16px;
 height: 31px;
 width: 100px;
 border-radius: 10px;
 border: none;
 box-shadow: 1px 1px 0px 2px rgba (0,0,0,0.3);
 cursor: pointer;
}

.pl {
    background-image: url('https://meansofproduction.biz/images/pl.png');
    background-size: 54px;
    background-repeat: no-repeat;
    background-color: white;
    height: 51px;
    width:  58px;
    border: 1px solid black;
    position: relative;
    top:  10px;
    left: 20px;
    display: inherit;
}
.hs {
    background-image: url('https://meansofproduction.biz/AC/img/dhaskell.jpg');
    background-size: 45px;
    background-repeat: no-repeat;
    height: 70x;
    width:  50px;
    border: 1px solid black;
    position: relative;
    top:  10px;
    left: 20px;
    display: inherit;
}
.cl {
    background-image: url('https://meansofproduction.biz/AC/img/disabled-lisp.jpg');
    background-size: 60px;
    background-repeat: no-repeat;
    background-color: white;
    height: 25px;
    width:  59px;
    border: 1px solid black;
    position: relative;
    top:  10px;
    left: 20px;
    display: inherit;
}
.cm {
    background-image: url('https://meansofproduction.biz/AC/img/console-48.png');
    background-size: 50px;
    background-repeat: no-repeat;
    background-color: rgb(70,130,180);
    background-position: center;
    height: 46px;
    width:  51px;
    border: 1px solid black;
    position: relative;
    top:  -5px;
    left: 20px;
    display: inherit;
}
.say {
    width: 50px;
    background-color: pink;
    color: black;
    border: 1px solid black;
    position: relative;
    top:  -20px;
    left: 25px;
    display: inherit;
}
.upload {
    width: 50px;;
    background-color: green;
    color: white;
    border: 2px solid black;
    position: relative;
    top:  10px;
    left: 25px;
    display: none;
}
.reset {
    width: 60px;
    background-color: darkred;
    color: white;
    border: 2px solid black;
    position: relative;
    top:  10px;
    left: 20px;
    display: inherit;
}
.sayBox {
    position: relative;
    width: 1296px;
    top: -50px;
    background-color: pink;
    color: black;
}
.acTranscript{
    background-image: url('https://meansofproduction.biz/AC/img/transcript.jpg');
    background-size: 200px;
    background-repeat: no-repeat;
    height: 500;
    width: 200;
    margin: "auto";
    color: "purple";
    font-size: 14px;
}
.toplegend { 
    top: -10px;
    left: -10;
    width: 500;
    position: relative;
    font-size: 10px;
    font-weight: bold;
}
.toplegen2 { 
    top: 5px;
    left: -10;
    width: 500;
    position: relative;
    font-size: 10px;
    font-weight: bold;
}
.legend { 
    top: -200px;
    left: -10;
    width: 500;
    position: relative;
    font-size: 12px;
    font-weight: bold;
}
.legend2 { 
    top: -35px;
    left: -10;
    width: 500;
    position: relative;
    font-size: 8px;
}

.topButt {
    height: 20px;
    display: none;
}
