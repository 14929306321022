.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.sayBox {
  border-radius: 5px;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.Button {
  margin: 3px;
  font-size: 16px;
  height: 31px;
  width: 100px;
  border-radius: 10px;
  border: none;
  box-shadow: 1px 1px 0px 2px rgba (0,0,0,0.3);
  cursor: pointer;
 }